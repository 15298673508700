import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import { Toast, Notify } from "vant";
import "./styles/common.scss";
import "vant/es/toast/style";
import "vant/es/notify/style";
import 'vant/es/image-preview/style'
import 'vant/es/dialog/style'
import 'element-plus/dist/index.css'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toast);
app.use(Notify);
app.config.globalProperties.$store = store;
app.config.globalProperties.$toast = Toast;
app.config.globalProperties.$notify = Notify;
app.mount("#app");
