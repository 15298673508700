import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/home.vue"),
    meta: {
      title: "网阅列表",
    },
  },
  {
    path: "/login",
    component: () => import("@/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/columnPage",
    component: () => import("@/views/columnPage.vue"),
    meta: {
      title: "手机阅卷",
    },
  },
  {
    path: "/temporaryMarking",
    component: () => import("@/views/temporaryMarking.vue"),
    meta: {
      title: "阅卷人员信息设置",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
